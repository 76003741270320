export default {
    computed: {
        bagColourDropdownOptions() {
            if (this.context && this.context.bag_colours) {
                return _.map(this.context.bag_colours, (colour) => {
                    return {
                        value: colour.id,
                        text: colour.name + " (" + colour.group + ")",
                    };
                });
            }
            return [];
        },

        bagIsExportedOptions() {
          return [
            {
              value: 0,
              text: 'Not exported',
            },
            {
              value: 1,
              text: 'Exported',
            },
          ]
        },

        bagConditionDropdownOptions() {
            if (this.context && this.context.bag_conditions) {
                return _.map(this.context.bag_conditions, (condition) => {
                    return {
                        value: condition.id,
                        text: condition.description
                    };
                });
            }
            return [];
        },

        bagStyleDropdownOptions() {
            if (this.context && this.context.bag_styles) {
                return _.map(this.context.bag_styles, (style) => {
                    return {
                        value: style.id,
                        text: style.description,
                    };
                });
            }
            return [];
        },

        bagSkinDropdownOptions() {
            if (this.context && this.context.bag_skins) {
                return _.map(this.context.bag_skins, (skin) => {
                    return {
                        value: skin.id,
                        text: skin.name,
                    };
                });
            }
            return [];
        },

        bagSizeDropdownOptions() {
            if (this.context && this.context.bag_sizes) {
                return _.map(this.context.bag_sizes, (size) => {
                    return {
                        value: size.id,
                        text: size.description,
                    };
                });
            }
            return [];
        },

        hardwareDropdownOptions() {
            if (this.context && this.context.hardwares) {
                return _.map(this.context.hardwares, (hardware) => {
                    return {
                        value: hardware.id,
                        text: hardware.name,
                    };
                });
            }
            return [];
        },

        locationDropdownOptions() {
            if (this.context && this.context.locations) {
                return _.map(this.context.locations, (location) => {
                    return {
                        value: location.id,
                        text: location.name,
                    };
                });
            }
            return [];
        }
    }
}
