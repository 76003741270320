import axios from 'axios';

export default {
    get(id) {
        return axios.get(window.API_BASE + '/products/' + id);
    },

    delete(id) {
        return axios.delete(window.API_BASE + '/products/' + id);
    },

    addProductImage(id, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(
            window.API_BASE + '/products/' + id + '/images',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    deleteProductImage(productId, imageId) {
        return axios.delete(window.API_BASE + '/products/' + productId + '/images/' + imageId);
    },

    search(params) {
        return axios.post(window.API_BASE + '/products/search', params);
    },

    create(params) {
        return axios.post(window.API_BASE + '/products', params);
    },

    searchInvoices(id, params = {}) {
        return axios.post(window.API_BASE + '/products/' + id + '/search-invoices', params);
    },

    suggestInvestors(id, params = {}) {
        return axios.post(window.API_BASE + '/products/' + id + '/suggest-investors', params);
    },

    assignInvestor(productId, accountId) {
        return axios.post(window.API_BASE + '/products/' + productId + '/assign-investor', {
            account_id: accountId
        });
    },

    removeInvestor(productId) {
        return axios.post(window.API_BASE + '/products/' + productId + '/remove-investor');
    },

    extendOffer(productId, accountIds, expiresAt) {
        return axios.post(window.API_BASE + '/products/' + productId + '/extend-offer', {
            account_ids: accountIds,
            expires_at: expiresAt
        });
    },

    markProductAsPurchasedNotReceived(productId, params) {
        return axios.post(window.API_BASE + '/products/' + productId + '/mark-as-purchased-not-received', params);
    },

    markProductAsInStock(productId, params) {
        return axios.post(window.API_BASE + '/products/' + productId + '/mark-as-in-stock', params);
    },

    markAsSold(productId, params) {
        return axios.post(window.API_BASE + '/products/' + productId + '/mark-as-sold', params);
    }
}